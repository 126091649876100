<template>
  <div class="footer">
<!--    <span v-if="content == ''"></span>-->
    <span v-html="content" ></span>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return{
      content: '<span>请先在后台添加页脚内容</span><br/><span>添加的内容使用html格式</span>',
    }
  },
  methods:{
    get_footer(){
      this.$axios.get('/config/footer/')
        .then((res => {
            this.content = res.data.data[0]['content']
        })).catch((error) => {
          this.$message.error('获取页脚接口出错');
      })
    }
  },
  mounted() {
    this.get_footer();
  }
}
</script>

<style scoped>
  .footer{
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: hotpink;
    /*color: #707c8e;*/
  }
</style>