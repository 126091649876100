import { createStore } from "vuex";
import { setItemByExpires } from '@/api/article';

export default createStore({
  state: {
    return: {
      // article_desc: localStorage.getItem('article_desc') || '',
      // article_content: localStorage.getItem('article_content') || '',
    }
  },
  getters: {},
  mutations: {
    store_article(state, article){      //存储点击后的文章内容，少请求一次接口
      state.article = article;
      // localStorage.setItem('article_title', article.title);
      // localStorage.setItem('article_desc', article.desc);
      // localStorage.setItem('article_content', article.content);
      // localStorage.setItem('article_content_html', article.content_html);
      // localStorage.setItem('pv', article.pv);
      // localStorage.setItem('uv', article.uv);
    }
  },
  actions: {},
  modules: {},
});
