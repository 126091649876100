import requests from "@/api/requests";

//获取每篇文章的pv总数
export function getViewCount(params){
  const url = '/blog/count/'
  return requests.get(url, params);
}

//获取每篇文章的评论总数
// export function getCommentCount(params){
//   const url = '/comment/comment/'
//   return requests.get(url, params);
// }

//获取文章或笔记总数
export function getArticleOrNoteCount(params){
    const url = '/blog/article/';
    return requests.get(url, params)
}

// 设置缓存方法，可提取为公共方法
export function setItemByExpires(key, value, expires) {
  const obj = {
    value, // 需要缓存的数据
    expires // 缓存有效期，毫秒为单位
  }
  localStorage.setItem(key, JSON.stringify(obj))
}

// 获取缓存方法，可提取为公共方法
export function getItemByExpires(key) {
  const value = localStorage.getItem(key)
  if (!value) return {}
  const obj = JSON.parse(value)
  if (obj['expires'] && new Date().getTime() > obj['expires']) {
    // 如果设置了有效期，且已过期，直接清楚
      console.log(key,' uuid已过期，清除')
    localStorage.removeItem(key)
    return ''
  }
  // 未设置有效期或设置了有效期但没有过期，直接将存储的数据返回
  return obj.value
}

//通过传入路由路径作为uuid_key，uuid作为value，时间作为过期时间，例如一分钟过期就是 60*1000，单位是毫秒，article_obj用于取id和uuid
// {'uv:/api/blog/article/1/': { "value":"d73500ef-073c-49bd-93cb-baf550e0a60b","expires":1693982616056 }
export function checkAndSetArticleUuid(uuid_key, uuid_value, article_obj, time_sec){
  //返回的是proxy {}对象，所以要通过JSON.stringify()将返回的数据转换成字符串，再判断是否为空
  if(JSON.stringify(uuid_value) === '{}' || uuid_value === '' ){ //如果没有uuid_value，说明是该时间内第一次访问
    // console.log('调用setItemByExpires设置uv或pv的uuid')
    // console.log('uuid_key: ', uuid_key)
    // console.log('uuid_value: ', uuid_value)
    // console.log('article_obj: ', article_obj)
    setItemByExpires(uuid_key + article_obj['id'] + '/',1,new Date().getTime() + time_sec);
  }
}

export function checkAndSetHomeUuid(uuid_key, uuid_value, time_sec){
  //返回的是proxy {}对象，所以要通过JSON.stringify()将返回的数据转换成字符串，再判断是否为空
  //取值可以这么取 JSON.stringify(val[0].id) ，先取再转换成json字符串
  if(JSON.stringify(uuid_value) === '{}' || uuid_value === '' ){ //如果没有uuid_value，说明是该时间内第一次访问
    // console.log('调用setItemByExpires设置uv或pv的uuid')
    // console.log('uuid_key: ', uuid_key)
    // console.log('uuid_value: ', uuid_value)
    // console.log('article_obj: ', article_obj)
    setItemByExpires(uuid_key , 1, new Date().getTime() + time_sec);
  }
}