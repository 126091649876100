import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//将自定义的axios配置在下面全局注册一下
import axios from "./axios/axios";
//导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//全局注册icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//全局中文，参考官方文档使用dist的库，lib下的提示找不到
import locale from 'element-plus/dist/locale/zh-cn'
//引入Elmessage和Elloading的css样式文件
import 'element-plus/theme-chalk/el-loading.css';
import 'element-plus/theme-chalk/el-message.css';

//引入代码高亮文件 npm install --save highlightjs/vue-plugin和npm install --save highlight.js
// import 'highlight.js/styles/tokyo-night-light.css'  //决定高亮的主题
// import 'highlight.js/lib/common';
// import hljs from "highlight.js";
// import { lineNumberInit } from "../src/api/highlightjs-line-numbers";  //https://blog.csdn.net/weixin_54858833/article/details/126031245
// lineNumberInit(hljs, window, window.document);

//解决ResizeObserver loop completed with undelivered notifications问题
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};

//将App.vue设置为总模板，并导入router组件
const app = createApp(App).use(router)

// // app.use(hljsVuePlugin)    //代码高亮
// // 定义自定义指令 highlight 使代码高亮
// app.directive('highlight',function (el) {
//  	let blocks = el.querySelectorAll('pre code');
//  	blocks.forEach((block)=>{
//   		hljs.highlightBlock(block);
// 	  	// hljs.lineNumbersBlock(block);   //行号没生效
//  	})
// });

app.use(ElementPlus, {locale: locale});

for (const iconName in ElementPlusIconsVue){
    app.component(iconName, ElementPlusIconsVue[iconName])
};

app.config.globalProperties.$axios = axios;
app.use(ElementPlus);
app.use(store);
app.mount('#app');

// createApp(App).use(store).use(router).mount("#app");
