<template>
<!--路由匹配到的组件内容显示位置，相当于占位符-->
  <router-view />
</template>

<style>
  html, body, #app {
    height: 100%;
    margin: 0;  /*消除顶部、左右间隙*/
    /*background-color: gainsboro;*/
    /*background-color: #faf5f5;*/
    /*background-color: white;*/
  }
</style>
