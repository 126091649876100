import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/views/Main";
import Main from "@/views/Main";

const routes = [
  {
    path: "/login",
    //懒加载，使用时才会加载
    component: () => import("../views/Login"),
  },
    {
        path: "/",
        name: "首页",
        component: Main,
        // redirect: '/article/list',
        meta: {
                title: 'CYG的博客-专注devops自动化运维，传播优秀it运维技术文章',
                keepAlive: true,
                isAuth: false
            },
    },
    {
        path: "/nav_article",
        name: "文章",
        component: () => import("../views/Main"),
        children: [
            {
                path: "/nav_article/:id/",
                component: () => import("../views/article/ArticleList"),
            }
        ]
    },
    {
        path: "/nav_note",
        name: "笔记",
        component: () => import("../views/Main"),
        children: [
            {
                path: "/nav_note/:id",
                component: () => import("../views/article/ArticleList"),
            }
        ]
    },
    // {
    //     path: "/records",
    //     name: "踩坑记录",
    //     component: () => import("../views/Main"),
    // },
    {
        path: "/about",
        name: "关于",
        component: () => import("../views/about/About"),
    },
    {
        path: "/article/:id/",
        component: () => import("../views/article/ArticleDetail"),
    },
    {
        path: "/note/:id",
        component: () => import("../views/Main"),
    },
    {
        path: "/management",
        name: "后台管理",
        component: () => import("../views/edit/Main"),
        redirect: '/dashboard',
        children: [
            {
                path: "/dashboard",
                name: "仪表盘",
                icon: 'HomeFilled',
                component: () => import("../views/edit/Dashboard"),
            }
        ]
    },
    {
        path: "/edit",
        name: '文章管理',
        icon: 'Platform',
        component: () => import("../views/edit/Main"),
        children: [
            {
                path: "/edit/article",
                name: '文章管理',
                component: () => import("../views/edit/article/Article"),
            },
            {
                path: "/edit/category",
                name: '分类管理',
                component: () => import("../views/edit/category/Category"),
            },
            {
                path: "/edit/tag",
                name: '标签管理',
                component: () => import("../views/edit/tag/Tag"),
            },
        ]
    },
    {
        path: "/comment",
        name: "评论管理",
        icon: 'Comment',
        component: () => import("../views/edit/Main"),
        children: [
            {
                path: "/comment/comment",
                name: "文章评论管理",
                component: () => import("../views/edit/comment/Comment"),
            },
            //留言版管理
        ]
    },
    {
        path: "/config",
        name: "配置管理",
        icon: 'Tools',
        component: () => import("../views/edit/Main"),
        children: [
            {
                path: "/config/about",
                name: "关于信息管理",
                component: () => import("../views/edit/config/about/About"),
            },
            {
                path: "/config/footer",
                name: "页脚管理",
                component: () => import("../views/edit/config/footer/Footer"),
            },
        ]
    },
    // {
    //     path: "setting",
    //     name: "通用配置",
    //     icon: 'Platform',
    //     component: () => import("../views/edit/Main"),
    //     children: [
    //         {
    //             path: "/setting/setting",
    //             name: "评论管理",
    //             component: () => import(""),
    //         }
    //     ]
    // }

];

const router = createRouter({
    history: createWebHistory(),          //路由模式
    routes,
});

router.beforeEach((to, from, next) =>{
   // //如果用户访问登录页，直接放行
   // if(to.path == "/login" || to.path == "/"){
   //     return next();
   // }


   const token = window.sessionStorage.getItem('token');
   if(token){
       next();  //正常跳转
   } else {
       if(to.path == '/edit' ) {
           return next('/login')  //跳转到登录页
       }else {
           return next();
       }
   }
});

export default router;
