<template>
    <el-menu
        :default-active="this.$route.path"
        class="el-menu-demo"
        mode="horizontal"
        router
        unique-opened
    >
<!--        <div class="logo">-->
<!--          <span>CYG的博客</span>-->
<!--        </div>-->

<!--        <div class="nav_content">-->
        <!-- 从$router中循环获取路由对象 -->
        <!-- 处理根路由 -->
        <template v-for="menu in this.$router.options.routes" :key="menu">
          <el-menu-item v-if="menu.path == '/' " :index="menu.path">
            <span class="nav_font">{{ menu.name }}</span>
          </el-menu-item>

          <!-- 文章分类栏 -->
          <el-sub-menu v-else-if="menu.path == '/nav_article' " :index="menu.path">
            <template #title><span class="nav_font">{{ menu.name }}</span></template>
              <el-menu-item v-for="item in this.article_items.data" :key="item.id" :index="menu.path + '/' + item.id">
                <span class="nav_font">{{ item.name }}</span>
              </el-menu-item>
          </el-sub-menu>

          <!-- 笔记分类栏 -->
          <el-sub-menu style="display: flex" v-else-if="menu.path == '/nav_note' " :index="menu.path">
            <template #title><span class="nav_font">{{ menu.name }}</span></template>
                <el-menu-item v-for="item in this.note_items.data" :key="item.id" :index="menu.path + '/' + item.id">
                  <span class="nav_font">{{ item.name }}</span>
                </el-menu-item>
          </el-sub-menu>

          <el-menu-item v-else-if="menu.path == '/records'" :index="menu.path">
            <span class="nav_font">{{ menu.name }}</span>
          </el-menu-item>
          <el-menu-item v-else-if="menu.path == '/about'" :index="menu.path">
            <span class="nav_font">{{ menu.name }}</span>
          </el-menu-item>
        </template>

<!--        <div class="search">-->
<!--          <el-input-->
<!--              placeholder="搜索文章标题、内容"-->
<!--              input-style="width: 220px;"-->
<!--          />-->
<!--        </div>-->

    </el-menu>


</template>

<script>

import {get_article_items, get_note_items} from "../../api/nav_items";


export default {
  name: "nav_items",
  data() {
    return{
      article_items: {},      //存放文章数据
      note_items: {},         //存放笔记数据
      essay_items: {},        //存放随笔数据
      hole_items: {},         //存放踩坑记录数据
      nav_items: {},          //用于处理nav显示的临时数据
      type_article: 1,        //文章
      type_node: 2,           //笔记
      type_essay: 3,          //随笔
      type_hole: 4            //踩坑记录
    }
  },
  watch:{

  },
  methods: {
    handleSelect(){

    },
    test(){
      console.log('article_items: ', this.nav_items[0])
      console.log('note_items: ', this.nav_items[1])
    },
    // to_article_nav_list(article){
    //   this.$router.push({
    //     path: `/nav_article/${article.id}` ,    //路由传参
    //   });
    // },
    // //获取nav栏文章二级菜单项
    // getArticleItems(url, params){
    //   this.$axios.get(url, {params: params}).then(res => {
    //       this.article_items = res.data;
    //     });
    //   // console.log('article_items: ', this.article_items)
    // },
    // //获取nav栏笔记二级菜单项
    // getNodeItems(url, params){
    //   this.$axios.get(url, {params: params}).then(res => {
    //       this.note_items = res.data;
    //     });
    //   // console.log('note_items: ', this.note_items)
    // },

  },
  mounted() {
    //这是封装axios后的写法，返回的是一个Promise对象，所以需要在.then中赋值，在.then中打印是空值，因为是异步方法，还没赋值就打印了
    get_article_items({'what_type': this.type_article}).then(res => this.article_items = res );
    get_note_items({'what_type': this.type_node}).then(res => this.note_items = res );

  },
}
</script>

<style scoped>
  .el-menu{
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 20px 0 #f5f5f5;
    /*background-color: #242424;*/
  }
  .nav_font{
    /*color: #ccc;*/
  }
  /*.logo{*/
  /*  width: 300px;*/
  /*}*/
  /*.nav_content{*/
  /*  !*display: flex;*!*/
  /*  !*width: 700px;*!*/
  /*}*/
  /*.search{*/
  /*  width: 300px;*/
  /*  display: flex;*/
  /*  justify-content: flex-end;*/
  /*  margin-right: 10px;*/
  /*}*/

</style>