import axios from "../axios/axios";

//里面的函数不用加function关键字了
// var result;
var requests = {
    //使用async声明这是一个异步方法，使用await等待axios执行完毕返回，因为axios本身就是一个异步方法
    async get(url, params) {
        // console.log(url + '?' + params)
        let result;
        await axios.get(url, {params: params})
            .then(res => {
                result = res.data;
            });
        // console.log('aaa: ', result)
        return result;
    },

    async post(url, params){
        let result;
        await axios.post(url, params)
            .then(res => {
                result = res.data;
            });
        return result;
    },

    async put(url, params){
        let result;
        await axios.put(url, params)
            .then(res => {
                result = res.data;
            });
        return result;
    },


}

export default requests