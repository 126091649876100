<template>
  <el-card class="box-card">
    <div class="card-header">
      <span>最新文章</span>
<!--      <div>-->
<!--        <el-breadcrumb :separator-icon="ArrowRight">-->
<!--        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item>文章</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item>{{ this.$route.path }}</el-breadcrumb-item>-->
<!--      </el-breadcrumb>-->
<!--      </div>-->
      <div class="display" v-show="visible" >
        <el-tag size="large" style="height: available" effect="dark">
          共 <span style="font-weight: bold; color: black">{{ this.total }}</span> 条结果
        </el-tag>
      </div>
      <div class="search">
        <el-input
            prefix-icon="Search"
            placeholder="搜索文章标题、内容"
            input-style="width: 220px;display: flex; justify-content: center; margin-right: 20px;--el-input-border-color: #42b983"
            clearable
            v-model="urlParams.search"
            @clear="getData"
            @input="getData"
            @keydown.enter="getData"
        />
      </div>
    </div>
    <el-card v-for="article in article_list.data"
           :key="article.id"
           body-style="padding: 0;"
           class="article_list_pre"
           shadow="never">
      <div class="article_list">
        <!-- 显示文章封面的图片，如果图片链接为空，就用前端默认的图片 -->
        <div v-if="article.icon_url == ''" class="image_pre">
          <img src="../../assets/img/icon_article.png" alt="" class="image">
        </div>
        <div v-else class="image_pre">
          <img :src="article.icon_url" class="image" />
        </div>

        <!-- 用于显示文章标题和三行摘要 -->
        <div class="article" >
          <div class="article_title" >
            <h2 @click="to_article_detail(article)">{{ article.title }}</h2>
          </div>

          <!-- 显示文章部分内容 -->
          <div class="article_content" >
            <span @click="to_article_detail(article)">{{ article.desc }}</span>
          </div>

          <!-- 用来显示点赞、评论等信息 -->
          <div class="article_card_bottom">
            <div class="what_type">
              <el-tag v-if="article.category.what_type_name == '文章' ">
                {{ article.category.what_type_name }}
              </el-tag>
              <el-tag type="success" v-if="article.category.what_type_name == '笔记' ">
                {{ article.category.what_type_name }}
              </el-tag>
            </div>
            <div class="statistics">
              <el-icon><View /></el-icon>
              <span class="view_count">{{ article.uv }}</span>
              <el-icon><ChatDotSquare /></el-icon>
              <div class="comment_count" >
                  {{ comment_count[article.id] }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </el-card>

    <!--分页-->
    <div style="margin-top: 20px;margin-bottom: 20px; display: flex; justify-content: right">
      <el-pagination
        v-model:currentPage="currentPage"
        :page-sizes="[10, 15, 20, 25, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

  </el-card>

  <el-backtop :right="50" :bottom="100" />

</template>

<script>

import { get_all_article_list, } from "@/api/article";
import { checkAndSetHomeUuid, getCount, setItemByExpires, getItemByExpires } from "@/api/Statistics";

export default {
  name: "article_list",
  data(){
    return{
      article_path: '/api/blog/article/',
      total: 0,  // 总数据条数
      pageSize: 10, // 默认每页的数据条数
      currentPage: 1,  // 默认开始页码
      urlParams: {     // URL查询参数
        page_size: 10,
        page_num: 1,
        category: '',
        status: 1,     //只取正常状态的文章
        search: '',
        pv_home_uuid: '',
        uv_home_uuid: '',
      },
      uv_home_path: 'uv:/',
      pv_home_path: 'pv:/',
      visible: false,
      article_list: {},
      comment_data: '',
      comment_count: {},
      // image_url: 'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png'
    }
  },
  mounted() {
    // get_all_article_list(this.url_params).then(res => this.article_list = res );
    this.getData();
    this.get_article_comment_count();
  },
  methods:{
    to_article_detail(article){            //试试点击文章详情打开新标签页
      this.$store.commit('store_article', article);    //把点击的文章内容保存到vuex中，
      let article_detail = this.$router.resolve({     //不使用push，使用resolve，在下面通过新标签页打开文章
        path: `/article/${article.id}` ,    //路由传参
        // query: {id: 1}                   //网站可以带参数
      });
      window.open(article_detail.href, '_blank')     //传入href网址对象
    },
    get_article_comment_count(){       //获取每篇文章的评论数
      this.$axios.get('/get_normal_comment/').then(res => {
        this.comment_data = res.data.data;
        window.localStorage.setItem('comment_count', res.data.data.length);  //正常评论总数，用于侧边栏统计，没有经过自定义分页
        // console.log('this.comment_data: ', this.comment_data)
        //下面这个for循环是为了统计每篇文章的评论数
        for(let i in this.article_list.data){
          // console.log('a_id: ', this.article_list.data[i].id)
          let count = 0;
          for (let j in this.comment_data){
            // console.log(this.comment_data[j].article.id)
            if(this.comment_data[j].article.id === this.article_list.data[i].id
                && (this.comment_data[j].tag ===1 || this.comment_data[j].tag ===2 || this.comment_data[j].tag ===3)
                && this.comment_data[j].status === 1 ){
              count += 1;
            }
          }
          this.comment_count[this.article_list.data[i].id] = count; //{1: 1, 2: 0, 26: 6}
          count = 0;
        }
        // console.log('this.comment_count: ', this.comment_count)
      })
    },
    getData() {
      this.visible = false;      //默认不显示文章统计，只在搜索和分类时显示
      this.urlParams.pv_home_uuid = getItemByExpires(this.pv_home_path);  //先检查一下pv_home_path是否过期
      this.urlParams.uv_home_uuid = getItemByExpires(this.uv_home_path);  //先检查一下uv_home_path是否过期
      if(this.$route.path === '/nav_article/' + this.$route.params.id){  //这里的id是分类的id，不是文章id
        // console.log('跳转到nav: ')
        this.urlParams.category = this.$route.params.id;
        this.visible = true;           //导航栏筛选分类时显示文章统计
      }
      if(this.$route.path === '/nav_note/' + this.$route.params.id){    //这里的id是笔记的id（分类what_type类型是笔记）
        this.urlParams.category = this.$route.params.id;
        this.visible = true;           //导航栏筛选分类时显示文章统计
      }

      if(!this.urlParams.category){
        delete this.urlParams.category;
      }
      if(!this.urlParams.search){         //没有搜索内容时就不带搜索参数
        delete this.urlParams.search;
      } else {
        this.visible = true;              //有搜索结果时显示文章统计
      }
      get_all_article_list(this.urlParams)
        .then(res => {
          // console.log(res.data);
          this.article_list = res;
          this.total = res.count;
          //检查或设置首页的pv
          checkAndSetHomeUuid(this.pv_home_path, this.urlParams.pv_home_uuid,60*1000);
          //检查或设置首页的uv
          checkAndSetHomeUuid(this.uv_home_path, this.urlParams.uv_home_uuid,24*60*60*1000);
        })
      this.urlParams.category = '';  //查询完后要清空，避免分类缓存没清回不到首页
    },
    // 分页：监听【选择每页数量】的事件
    handleSizeChange(pageSize) {
        // console.log(pageSize)
        this.currentPage = 1;          //这两行是点击每页显示多少行时，回到第一页
        this.urlParams.page_num = 1;   //这样可以修复先点击第二页，再选择显示20条时还在第二页，但是总条数才12条共一页报错的问题
        this.pageSize = pageSize; // 重新设置分页显示
        this.urlParams.page_size = pageSize;  // 将最新值设置到数据里，用于下面用新值重新获取数据
        this.getData()
    },
    // 分页：监听【点击页码】的事件
    handleCurrentChange(currentPage) {
        // console.log(currentPage)
        this.currentPage = currentPage; // 重新设置分页显示
        this.urlParams.page_num = currentPage;
        this.getData()
    },
    to_top(){
      // top.scrollIntoView();
      document.documentElement.scrollTop = 0;
    }
  },
  watch:{
    $route(to, from){                    //监控路由变化，执行函数更新数据
      // console.log('to: ', to.path)
      // console.log('from: ', from.path)
      deep: true               // 深度观察监听
      this.getData();
      this.to_top();      //路由改变了，回到顶部
    }
  }
}
</script>

<style scoped>
  .box-card{
    background-color: white;
    width: 960px;
  }
  .card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #42b983;
    font-size: 25px;
    margin: 12px 12px 12px 12px;
    background-color: white;
  }
  .display{
    display: flex;
    align-items: center;
  }
  .search>>>.el-input__wrapper{  /* 父级>>>el-input里面的深层class属性，可以调整相关样式  */
    padding: 1px 11px 1px 11px;
    /*--el-input-border-color: #193984; 在上面的el-input里的style中加上这个，改变边框颜色 */
  }
  .article_list_pre:hover{                   /* :hover 鼠标移动到上面时 */
    /*padding: 20px 20px 20px 20px;*/
    box-shadow: 5px 5px 5px 5px #3037421a;        /* 上右下左，加阴影 */
    transform: translateY(-10px);                /* 组件网上移动10px */
    /*transition-delay: 0s !important;             !* 优先级最高，尽量不要使用 *!*/
  }
  .article_list{
    /*border-top: 1px solid grey;*/
    background-color: white;
    height: 236.6px;
    display: flex;
    align-items: end;
    justify-content: space-between;
  }
  .image_pre{
    width: 236px;
    height: 236px;
    display: flex;
    align-items: center;
  }
  .image{
    height: 90%;
    /*width: 90%;*/
    border-radius: 10px;
    margin: 0px 12px 0px 12px ;
  }
  .article{
    /*margin-left: 10px;*/
    background-color: white;
    /*width: 642px;*/
    width: 100%;
    height: 100%;
    word-break: break-word;
  }
  .article_title{
    margin: 30px 0px 30px 0px;
    /*background-color: whitesmoke;*/
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    text-align: center;
    font-weight: bold;
  }
  .article_content{
    /*background-color: whitesmoke;*/
    color: dimgray;
    margin: 0 20px 0 20px;
    /*width: 590px;*/
    /*height: 64px;*/
    height: 96px;
    line-height: 2em;
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    display: -webkit-box;  /*必须结合的属性，将对象作为弹性伸缩盒子模型显示。*/
    -webkit-box-orient: vertical;  /*必须结合的属性，设置或检索伸缩盒对象的子元素的排列方式*/
    -webkit-line-clamp: 3;  /*用来限制在一块元素显示的文本的行数。为了实现该效果，它需要组合上面两个webkit属性。*/
    overflow: hidden;
  }
  .article_title h2:hover,.article_content span{
    cursor: pointer;
  }
  .article_card_bottom{
    /*background-color: lightgrey;*/
    height: 35px;
    margin: 5px 20px 5px 0;  /* 上右下左 */
    display: flex;
    align-items: center;
    justify-content: right;
    /*background-color: blue;*/
    /*border: 1px solid ;*/
  }
  .what_type{
    margin: 0 20px 0 0;
  }
  .statistics{
    display: flex;
    align-items: center;
  }
  .view_count, .comment_count{
    margin-left: 5px;
    margin-right: 5px;
  }
</style>