<template>
  <el-card class="box-card" shadow="never">
      <template #header>
        <div class="card-header">
          <span>网站统计</span>
        </div>
      </template>
      <div class="text_item">
        <div class="item">
          <el-icon><Clock /></el-icon>
          <span class="text">网站共运行了{{this.run_day}}天<br/>
            {{this.run_hours}}小时{{this.run_minutes}}分钟{{this.run_seconds}}秒</span>
        </div>
        <div class="item">
          <el-icon><View /></el-icon>
          <span class="text">今日共有{{ blog_count.uv }}位访客</span>
        </div>
        <div class="item">
          <el-icon><Document /></el-icon>
          <span class="text">共有{{ article_count }}篇文章</span>
        </div>
        <div class="item">
          <el-icon><Notebook /></el-icon>
          <span class="text">共有{{ note_count }}篇笔记</span>
        </div>
        <div class="item">
          <el-icon><ChatDotSquare /></el-icon>
          <span class="text">共有{{ comment_count }}条评论</span>
        </div>
      </div>
    </el-card>
</template>

<script>
import {getViewCount, getArticleOrNoteCount, getItemByExpires, checkAndSetHomeUuid} from "@/api/Statistics";

export default {
  name: "Statistics",
  data(){
    return{
      blog_count: '',
      comment_count: 0,
      article_count: 0,
      note_count: 0,
      run_time: '',
      run_day: '',
      run_hours: '',
      run_minutes: '',
      run_seconds: '',
      run_time_detail: '',
      urlParams: {     // URL查询参数
        pv_home_uuid: '',
        uv_home_uuid: '',
        no_add_home_pvuv: true,
      },
      uv_home_path: 'uv:/',
      pv_home_path: 'pv:/',
    }
  },
  methods: {
    get_count(){
      getViewCount({'page_size': 10, 'page_num': 1,}).then(res => {
        this.blog_count = res.data[0];
      });

      this.urlParams.pv_home_uuid = getItemByExpires(this.pv_home_path);  //先检查一下pv_home_path是否过期
      this.urlParams.uv_home_uuid = getItemByExpires(this.uv_home_path);  //先检查一下uv_home_path是否过期
      getArticleOrNoteCount(this.urlParams).then(res =>{      //统计侧边栏的文章、评论数
        // console.log('getArticleOrNoteCount', res.data)
        let all_article = res.data;
        for(let i in all_article){
          if(all_article[i].category.what_type === 1){
            this.article_count += 1;
          } else if(all_article[i].category.what_type === 2){
            this.note_count += 1;
          }
        }
        //检查或设置首页的pv
          checkAndSetHomeUuid(this.pv_home_path, this.urlParams.pv_home_uuid,60*1000);
          //检查或设置首页的uv
          checkAndSetHomeUuid(this.uv_home_path, this.urlParams.uv_home_uuid,24*60*60*1000);
      })
      this.comment_count = window.localStorage.getItem('comment_count');
    },
    computer_starttime(){   //计算网站启动时间
      this.run_time = (new Date() - new Date(this.blog_count.start_time))/1000/60/60/24; //总共运行了多少天，例如1.243天
      this.run_day = parseInt(this.run_time)     //取整，也可以试试 ~~this.run_time
      this.run_hours = parseInt((this.run_time - this.run_day) * 24)  //取小时
      this.run_minutes = parseInt(((this.run_time - this.run_day)*24 - this.run_hours) * 60 ) //取分钟
      this.run_seconds = parseInt((((this.run_time - this.run_day)*24 - this.run_hours)*60 - this.run_minutes) * 60) //取分钟
      // console.log('运行了',this.run_day, '天',this.run_hours, '小时', this.run_minutes, '分钟', this.run_seconds,'秒')
    }
  },
  mounted() {
    this.get_count();
    var run_time_detail= setInterval(()=>{    //让运行时间跳动起来
      this.computer_starttime();
    }, 1000)
  },
  computed: {
    // run_time_detail: setInterval(this.computer_starttime, 1000)
  }
}
</script>

<style scoped>
  .text_item{

  }
  .item{
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
  }
  .text{
    margin: 0 0 0 10px;
  }
</style>