<template>
  <div class="common-layout">
    <el-container >
      <el-header>
        <nav_items></nav_items>
      </el-header>

      <div class="el-content-main">
        <div class="main-and-aside">
            <el-main>
              <article_list></article_list>
            </el-main>

            <el-aside>
              <AsideMain></AsideMain>
            </el-aside>

          </div>
      </div>

      <el-footer>
        <Footer></Footer>
      </el-footer>

    </el-container>
  </div>

</template>

<script>
    import nav_items from "@/views/nav/NavItems";
    import Article_list from "@/views/article/ArticleList";
    import AsideMain from "@/views/side/AsideMain";
    import {get_article_items, get_note_items} from "@/api/nav_items";
    import Footer from "@/views/foot/Footer";

    export default {
      name: "Layout",
      components: {AsideMain, Article_list, nav_items, Footer},
      data() {
        return {

        }
      },
      methods: {

      },
      mounted() {

      }
    }
</script>

<style scoped>
  .el-header{       /*解决header不能铺满的问题*/
    padding: 0;
    /* 固定住顶栏 */
    /*height: 60px;*/
    /*width: 100%;*/
    position: sticky;    /* fixed必须和height、width配合使用 ，而sticky必须和top、bottom、left、right等四个参数配合使用 */
    top: 0px;
    z-index: 9999;
  }
  .el-content-main{
    padding: 20px 20px 20px 20px;    /* 去掉了el-main自带的padding，在这里统一控制 */
    display: flex;
    justify-content: center;         /*在这里调才能让组件居中*/
    background-color: whitesmoke;
  }
  .main-and-aside{
    display: flex;
    align-items: flex-start;       /* 在这里调能让组件靠上 */
    /*justify-items: center;*/
  }
  .el-main{
    /*background-color: whitesmoke;*/
    padding: 0px !important;
    /*margin: 0;*/
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-aside{
    margin: 0 0 0 20px ;
    background-color: white;
    width: 260px;
    /*height: 400px;*/
    height: auto;
  }
  .el-footer{
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>