import requests from "@/api/requests";


//获取所有文章列表
export function get_all_article_list(params){
    const url = '/blog/article/';
    return requests.get(url, params)
}

//根据id获取文章详情
export function get_article_detail_by_id(params){
    const url = '/blog/article/';
    return requests.get(url, params)
}

//获取所有分类信息
export function get_all_categorys(params){
    const url = '/blog/category/';
    return requests.get(url, params)
}

//获取所有标签信息
export function get_all_tags(params){
    const url = '/blog/tag/';
    return requests.get(url, params)
}

//新增文章，暂时没有用上
export function post_new_article(params){
    const url = '/blog/article/';
    return requests.post(url, params)
}



