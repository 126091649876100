import requests from "@/api/requests";

//获取文章列表
export function get_article_items(params){
    const url = '/blog/category/';
    return requests.get(url, params);
}

//获取笔记列表
export function get_note_items(params){
    const url = '/blog/category/';
    return requests.get(url, params);
}

