<template>
  <el-container direction="vertical">
    <Statistics></Statistics>
  </el-container>
</template>

<script>

import Statistics from "@/views/side/Statistics";

export default {
  components: { Statistics },
  name: "AsideMain"
}
</script>

<style scoped>

</style>